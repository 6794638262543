import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'

Vue.config.productionTip = false
Vue.prototype.$eventHub = new Vue()

// handling ChunkLoadError
router.onError(error => {
  if (/loading( css)? chunk /i.test(error.message) && navigator.onLine) {
    window.location.reload()
  }
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
