/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAccount = /* GraphQL */ `
  query GetAccount($id: ID!) {
    getAccount(id: $id) {
      id
      label
      subtype
      status
      created_at
      created_by
      updated_at
      updated_by
      expires_at
      config {
        external
        scopes
        grant_types
        response_types
        redirect_uris
        login_uri
        code_challenge_methods
        signed_request
        jwks_uri
        authentication {
          method
          factor
        }
        tokens {
          access {
            exp
            use
            aud
            ext
            jwk
          }
          identity {
            exp
            use
            ext
            jwk
          }
          refresh {
            exp
            use
          }
          code {
            exp
            use
          }
        }
      }
    }
  }
`
export const getFactor = /* GraphQL */ `
  query GetFactor($id: ID!) {
    getFactor(id: $id) {
      id
      label
      config {
        case_sensitive
        public_signup
        unique
        require_validation_for_enablement
        max_attempts
        response_type
        issuer
        client_id
        client_secret
        client_keys {
          public
          private
        }
        client_authentication
        signed_request
        authorization_endpoint
        response_mode
        scope
        jwks_uri
        content_type
        token_endpoint
        regex
        otp
        userinfo_endpoint
        code_challenge_method
        code_challenge_regex
        nonce
        nonce_regex
        window
        auto_unlock
        capture_input
        capture_claims
        capture_tokens
      }
      score
      subtype
      created_at
      created_by
      updated_at
      updated_by
      status
    }
  }
`
export const getEnrollment = /* GraphQL */ `
  query GetEnrollment($id: ID!) {
    getEnrollment(id: $id) {
      id
      label
      factor
      score
      subtype
      account
      created_at
      created_by
      updated_at
      updated_by
      expires_at
      status
    }
  }
`
export const getTenant = /* GraphQL */ `
  query GetTenant($id: ID!) {
    getTenant(id: $id) {
      id
      label
      subscription
      account
      admin_client
      metrics {
        maa
        mac
        updated_at
      }
      config {
        color
        tokens {
          login {
            exp
          }
          signup {
            exp
          }
        }
        accounts {
          pending {
            exp
          }
          enabled {
            exp
          }
        }
        hlp {
          background_color
          background_text_color
          background_link_color
          widget_color
          widget_text_color
          widget_link_color
          logo
        }
      }
      status
      created_at
      created_by
      updated_at
      updated_by
      expires_at
    }
  }
`
export const getExtension = /* GraphQL */ `
  query GetExtension($id: ID!) {
    getExtension(id: $id) {
      id
      config {
        code
        rule {
          type
          origin
          action
          result
          reason
          account
        }
      }
      label
      status
      build {
        status
        updated_at
      }
      created_at
      created_by
      updated_at
      updated_by
      expires_at
    }
  }
`
export const getToken = /* GraphQL */ `
  query GetToken($id: ID!) {
    getToken(id: $id) {
      id
      label
      type
      tenant
      account
      created_at
      created_by
      updated_at
      updated_by
    }
  }
`
export const getRule = /* GraphQL */ `
  query GetRule($id: ID!) {
    getRule(id: $id) {
      id
      label
      account
      control
      required
      status
      created_at
      created_by
      updated_at
      updated_by
      expires_at
    }
  }
`
export const listAccounts = /* GraphQL */ `
  query ListAccounts($filter: TableAccountFilterInput, $limit: Int, $nextToken: String) {
    listAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        subtype
        label
        status
        created_at
        created_by
        updated_at
        updated_by
        expires_at
        config {
          scopes
          redirect_uris
          grant_types
          response_types
        }
      }
      nextToken
    }
  }
`
export const listFactors = /* GraphQL */ `
  query ListFactors($filter: TableFactorFilterInput, $limit: Int, $nextToken: String) {
    listFactors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        label
        subtype
        config {
          case_sensitive
          public_signup
          unique
          require_validation_for_enablement
          max_attempts
          response_type
          issuer
          client_id
          authorization_endpoint
          response_mode
          scope
          jwks_uri
          content_type
          token_endpoint
          regex
          otp
          userinfo_endpoint
          code_challenge_method
          code_challenge_regex
          nonce
          nonce_regex
          window
          auto_unlock
          capture_input
          capture_claims
          capture_tokens
        }
        score
        created_at
        created_by
        updated_at
        updated_by
        status
      }
      nextToken
    }
  }
`
export const listEnrollments = /* GraphQL */ `
  query ListEnrollments($filter: TableEnrollmentFilterInput, $limit: Int, $nextToken: String) {
    listEnrollments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        label
        subtype
        factor
        account
        created_at
        created_by
        updated_at
        updated_by
        status
      }
      nextToken
    }
  }
`
export const listTenants = /* GraphQL */ `
  query ListTenants($filter: TableTenantFilterInput, $limit: Int, $nextToken: String) {
    listTenants(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        label
        subscription
        admin_client
        account
        status
        metrics {
          maa
          mac
          updated_at
        }
        created_at
        created_by
        updated_at
        updated_by
        expires_at
      }
      nextToken
    }
  }
`
export const listTokens = /* GraphQL */ `
  query ListTokens($filter: TableTokenFilterInput, $limit: Int, $nextToken: String) {
    listTokens(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        tenant
        account
        status
        created_at
        created_by
        updated_at
        updated_by
        expires_at
      }
      nextToken
    }
  }
`
export const listExtensions = /* GraphQL */ `
  query ListExtensions($filter: TableExtensionFilterInput, $limit: Int, $nextToken: String) {
    listExtensions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        label
        config {
          code
          rule {
            type
            origin
            action
            result
            reason
            account
          }
        }
        status
        build {
          status
          updated_at
        }
        created_at
        created_by
        updated_at
        updated_by
        expires_at
      }
      nextToken
    }
  }
`
export const listConsents = /* GraphQL */ `
  query listConsents($filter: TableConsentFilterInput, $limit: Int, $nextToken: String) {
    listConsents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        account
        label
        status
        recipient
        control
        created_at
        created_by
        updated_at
        updated_by
      }
      nextToken
    }
  }
`
export const listRules = /* GraphQL */ `
  query listRules($filter: TableRuleFilterInput, $limit: Int, $nextToken: String) {
    listRules(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        control
        account
        label
        required
        status
        created_at
        created_by
        updated_at
        updated_by
        expires_at
      }
      nextToken
    }
  }
`
export const listPermissions = /* GraphQL */ `
  query listPermissions($filter: TablePermissionFilterInput, $limit: Int, $nextToken: String) {
    listPermissions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        control
        account
        label
        status
        created_at
        created_by
        updated_at
        updated_by
        expires_at
      }
      nextToken
    }
  }
`
export const listControls = /* GraphQL */ `
  query ListControls($filter: TableControlFilterInput, $limit: Int, $nextToken: String) {
    listControls(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        config {
          consent_required
          permission_required
        }
        score
        subtype
        label
        status
        value
        created_at
        created_by
        updated_at
        updated_by
      }
      nextToken
    }
  }
`
export const getControl = /* GraphQL */ `
  query GetControl($id: ID!) {
    getControl(id: $id) {
      id
      config {
        consent_required
        permission_required
      }
      score
      subtype
      label
      status
      value
      created_at
      created_by
      updated_at
      updated_by
    }
  }
`
export const getConsent = /* GraphQL */ `
  query getConsent($id: ID!) {
    getConsent(id: $id) {
      id
      type
      subtype
      tenant
      label
      status
      value
      control
      account
      recipient
      choice
      created_at
      created_by
      updated_at
      updated_by
    }
  }
`
export const getPermission = /* GraphQL */ `
  query getPermission($id: ID!) {
    getPermission(id: $id) {
      id
      type
      subtype
      tenant
      label
      status
      value
      control
      recipient
      created_at
      created_by
      updated_at
      updated_by
    }
  }
`
export const listEvents= /* GraphQL */ `
  query listEvents($filter: TableEventFilterInput, $limit: Int, $nextToken: String) {
    listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        time
        type
        origin
        action
        result
        reason
        account_id
        detail
      }
      nextToken
    }
  }
`