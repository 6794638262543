<template>
  <v-app>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
export default {

  /**
   * created
   */
  async created() {
    // theme
    let c = document.cookie.split(';').find(item => item.trim().startsWith('darktheme'))
    if (c) c = c.trim().replace('darktheme=', '')
    this.$vuetify.theme.dark = (c === 'true')
  },

}
</script>

<style>
</style>
