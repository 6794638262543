<template>
  <v-menu
    offset-y
    left
    nudge-bottom="14"
    min-width="230"
    content-class="user-profile-menu-content"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        bottom
        color="success"
        overlap
        offset-x="12"
        offset-y="12"
        class="ms-4"
        dot
      >
        <v-avatar
          size="40px"
          v-bind="attrs"
          v-on="on"
        >
          <v-img :src="require('@/assets/images/avatars/anonym.png')"></v-img>
        </v-avatar>
      </v-badge>
    </template>
    <v-list>
      <div class="pb-3 pt-2">
        <v-badge
          bottom
          color="success"
          overlap
          offset-x="12"
          offset-y="12"
          class="ms-4"
          dot
        >
          <v-avatar size="40px">
            <v-img :src="require('@/assets/images/avatars/anonym.png')"></v-img>
          </v-avatar>
        </v-badge>
        <div
          class="d-inline-flex flex-column justify-center ms-3"
          style="vertical-align:middle"
        >
          <span class="text--primary font-weight-semibold mb-n1">
            You
          </span>
          <!-- <small class="text--disabled text-capitalize">Admin</small> -->
        </div>
      </div>

      <!-- Profile -->
      <v-list-item
        link
        :href="userPortalBillingUrl"
        target="_blank"
      >
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiCogOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Account & Billing</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- Billing -->
      <!--
      <v-list-item
        link
        :href="userPortalBillingUrl"
        target="_blank"
      >
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiCurrencyEur }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Billing</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="my-2"></v-divider>
      -->

      <!-- Docs -->
      <v-list-item
        link
        href="https://docs.quasr.io"
        target="_blank"
      >
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiTextBoxMultipleOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Documentation</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- Chat -->
      <v-list-item
        link
        href="https://discord.com/channels/895325971278856292/895413575491936257"
        target="_blank"
      >
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiChatOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Discord Community</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- Status -->
      <v-list-item
        link
        href="https://secure-stats.pingdom.com/1wgwg1ti7t35"
        target="_blank"
      >
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiWeatherPartlyLightning }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Status Page</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!--
      <v-list-item
        link
        href="mailto:support@quasr.io"
        target="_blank"
      >
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiLifebuoy }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Support</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      -->

      <v-divider class="my-2"></v-divider>

      <!-- Logout -->
      <v-list-item
        link
        @click="logout()"
      >
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiLogoutVariant }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import {
  mdiAccountOutline,
  mdiEmailOutline,
  mdiCheckboxMarkedOutline,
  mdiChatOutline,
  mdiCogOutline,
  mdiCurrencyEur,
  mdiHelpCircleOutline,
  mdiTextBoxMultipleOutline,
  mdiLogoutVariant,
  mdiLifebuoy,
  mdiWeatherPartlyLightning,
} from '@mdi/js'
import * as QuasrHelper from '@quasr-io/helper'

export default {
  setup() {
    const ENV = QuasrHelper.getEnv()
    const API_ENV = QuasrHelper.getApiEnv()
    const QUASR_ENV = QuasrHelper.getTenantAndClient()
    let userPortalUrl = document.location.host === 'admin-loc.quasr.io:8080' || document.location.host === 'account-loc.quasr.io:8080' ? 'http://account-loc.quasr.io:8080' : `https://account-${ENV}.quasr.io`
    if (API_ENV === 'prod') userPortalUrl = 'https://account.quasr.io'
    const userPortalBillingUrl = userPortalUrl

    return {
      ENV,
      API_ENV,
      QUASR_ENV,
      userPortalUrl,
      userPortalBillingUrl,
      icons: {
        mdiAccountOutline,
        mdiEmailOutline,
        mdiCheckboxMarkedOutline,
        mdiChatOutline,
        mdiCogOutline,
        mdiCurrencyEur,
        mdiHelpCircleOutline,
        mdiTextBoxMultipleOutline,
        mdiLogoutVariant,
        mdiLifebuoy,
        mdiWeatherPartlyLightning,
      },
    }
  },
  methods: {
    logout() {
      localStorage.clear()
      this.$router.push(`/${this.QUASR_ENV.tenant_id}/${this.QUASR_ENV.client_id}/logout`)
    },
  },
}
</script>

<style lang="scss">
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
</style>
