<template>
  <v-fade-transition mode="out-in">
    <v-icon
      :key="$vuetify.theme.dark"
      @click="toggleTheme()"
    >
      {{ $vuetify.theme.dark ? icons.mdiWeatherSunny : icons.mdiWeatherNight }}
    </v-icon>
  </v-fade-transition>
</template>

<script>
import { mdiWeatherNight, mdiWeatherSunny } from '@mdi/js'

export default {
  /**
   * created
   */
  created() {
    let c = document.cookie.split(';').find(item => item.trim().startsWith('darktheme'))
    if (c) c = c.trim().replace('darktheme=', '')
    this.$vuetify.theme.dark = (c === 'true')

    // this.$vuetify.theme.dark = localStorage.getItem('darktheme') === 'true'
  },

  /**
   * methods
   */
  methods: {
    toggleTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
      localStorage.setItem('darktheme', this.$vuetify.theme.dark)
      document.cookie = `darktheme=${this.$vuetify.theme.dark};Domain=.quasr.io;SameSite=Lax;Path=/`
    },
  },

  /**
   * setup
   */
  setup() {
    return {
      icons: {
        mdiWeatherNight,
        mdiWeatherSunny,
      },
    }
  },
}
</script>

<style>
</style>
