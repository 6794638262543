import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'default',
    component: () => import('@/components/Login.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/:tenant_id/:client_id',
    name: 'default-wpath',
    component: () => import('@/components/Login.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/:tenant_id/:client_id/login',
    name: 'login',
    component: () => import('@/components/Login.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/:tenant_id/:client_id/logout',
    props: { mode: 'logout' },
    name: 'logout',
    component: () => import('@/components/Login.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/:tenant_id/:client_id/enrollment-callback',
    name: 'enrollment-callback',
    component: () => import('@/components/EnrollmentCallback.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/:tenant_id/:client_id/dashboard',
    name: 'dashboard',
    component: () => import('@/components/dashboard/Dashboard.vue'),
  },
  {
    path: '/:tenant_id/:client_id/tenants/:id',
    props: true,
    name: 'tenant-settings',
    component: () => import('@/components/tenant/TenantSettings.vue'),
  },
  {
    path: '/:tenant_id/:client_id/accounts',
    props: { subtype: undefined, title: 'Accounts' },
    name: 'accounts-table',
    component: () => import('@/components/account/AccountsTable.vue'),
  },
  {
    path: '/:tenant_id/:client_id/clients',
    props: { subtype: 'client', title: 'Clients' },
    name: 'clients-table',
    component: () => import('@/components/account/AccountsTable.vue'),
  },
  {
    path: '/:tenant_id/:client_id/users',
    props: { subtype: 'user', title: 'Users' },
    name: 'users-table',
    component: () => import('@/components/account/AccountsTable.vue'),
  },
  {
    path: '/:tenant_id/:client_id/accounts/:id',
    props: { subtype: undefined, title: 'Accounts' },
    name: 'account-settings',
    component: () => import('@/components/account/AccountSettings.vue'),
  },
  {
    path: '/:tenant_id/:client_id/users/:id',
    props: { subtype: 'user', title: 'Users' },
    name: 'user-settings',
    component: () => import('@/components/account/AccountSettings.vue'),
  },
  {
    path: '/:tenant_id/:client_id/clients/:id',
    props: { subtype: 'client', title: 'Clients' },
    name: 'client-settings',
    component: () => import('@/components/account/AccountSettings.vue'),
  },
  {
    path: '/:tenant_id/:client_id/extensions',
    props: { subtype: 'code' },
    name: 'extensions',
    component: () => import('@/components/extension/ExtensionsTable.vue'),
  },
  {
    path: '/:tenant_id/:client_id/extensions/:id',
    props: { subtype: 'code' },
    name: 'extension-settings',
    component: () => import('@/components/extension/CodeSettings.vue'),
  },
  {
    path: '/:tenant_id/:client_id/webhooks/:id',
    name: 'webhook-settings',
    component: () => import('@/components/extension/WebhookSettings.vue'),
  },
  {
    path: '/:tenant_id/:client_id/webhooks',
    props: { subtype: 'webhook' },
    name: 'webhooks-table',
    component: () => import('@/components/extension/ExtensionsTable.vue'),
  },
  {
    path: '/:tenant_id/:client_id/apis',
    name: 'apis-table',
    component: () => import('@/components/apis/ApiSettings.vue'),
  },
  {
    path: '/:tenant_id/:client_id/hlp/:id',
    name: 'hlp-settings',
    component: () => import('@/components/hlp/HLPSettings.vue'),
  },
  {
    path: '/:tenant_id/:client_id/controls',
    name: 'controls-table',
    component: () => import('@/components/control/ControlsTable.vue'),
  },
  {
    path: '/:tenant_id/:client_id/events',
    name: 'events-table',
    component: () => import('@/components/event/EventsTable.vue'),
  },
  {
    path: '/:tenant_id/:client_id/controls/:id',
    name: 'control-settings',
    component: () => import('@/components/control/ControlSettings.vue'),
  },
  {
    path: '/:tenant_id/:client_id/rules/:id',
    name: 'rule-settings',
    component: () => import('@/components/control/RuleSettings.vue'),
  },
  {
    path: '/:tenant_id/:client_id/consents',
    name: 'consents-table',
    component: () => import('@/components/control/ConsentsTable.vue'),
  },
  {
    path: '/:tenant_id/:client_id/consents/:id',
    name: 'consent-settings',
    component: () => import('@/components/control/ConsentSettings.vue'),
  },
  {
    path: '/:tenant_id/:client_id/permissions',
    name: 'permissions-table',
    component: () => import('@/components/control/PermissionsTable.vue'),
  },
  {
    path: '/:tenant_id/:client_id/permissions/:id',
    name: 'permission-settings',
    component: () => import('@/components/control/PermissionSettings.vue'),
  },
  {
    path: '/:tenant_id/:client_id/factors',
    props: { type: 'factor', title: 'Factors', filter: { type: 'factor' } },
    name: 'factors-table',
    component: () => import('@/components/factor/FactorsTable.vue'),
  },
  {
    path: '/:tenant_id/:client_id/factors/:id',
    props: { type: 'factor', title: 'Factor Settings', filter: { type: 'factor' } },
    name: 'factor-settings',
    component: () => import('@/components/factor/FactorSettings.vue'),
  },
  {
    path: '/:tenant_id/:client_id/enrollments/:id',
    alias: '/:tenant_id/:client_id/security/:id',
    props: { type: 'enrollment', title: 'Factor Enrollment Settings', filter: { type: 'enrollment' } },
    name: 'enrollment-settings',
    component: () => import('@/components/enrollment/EnrollmentSettings.vue'),
  },
  {
    path: '/:tenant_id/:client_id/tokens',
    name: 'tokens-table',
    component: () => import('@/components/token/TokensTable.vue'),
  },
  {
    path: '/:tenant_id/:client_id/security',
    props: { title: 'Security' },
    name: 'user-security',
    component: () => import('@/components/user/security/UserSecurity.vue'),
  },
  {
    path: '/error',
    name: 'error',
    component: () => import('@/components/Error.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/:tenant_id/:client_id/error',
    name: 'error-wpath',
    component: () => import('@/components/Error.vue'),
    meta: {
      layout: 'blank',
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
