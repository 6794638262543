<template>
  <v-navigation-drawer
    :value="isDrawerOpen"
    app
    floating
    width="260"
    class="app-navigation-menu"
    :right="$vuetify.rtl"
    @input="val => $emit('update:is-drawer-open', val)"
  >
    <!-- Navigation Header -->
    <div class="vertical-nav-header d-flex items-center ps-6 pe-5 pt-5 pb-2">
      <router-link
        to="/"
        class="quasr-nav-title d-flex align-center text-decoration-none"
      >
        <v-img
          :src="require(`@/assets/images/logos/brand-${$vuetify.theme.dark ? 'white' : 'blue'}.png`)"
          max-height="30px"
          max-width="117px"
          alt="Quar Logo"
          contain
          eager
          class="app-logo me-3"
        ></v-img> <span :style="getLinkStyle()">| {{ uiMode === 'admin' ? 'Tenant Admin' : 'Account' }}</span>
      </router-link>
    </div>

    <!-- Navigation Items Admin -->
    <v-list
      v-if="uiMode === 'admin'"
      expand
      shaped
      class="vertical-nav-menu-items pr-5"
    >
      <nav-menu-link
        title="Dashboard"
        :to="{ path: `/${QUASR_ENV.tenant_id}/${QUASR_ENV.client_id}/dashboard` }"
        :icon="icons.mdiHomeOutline"
      ></nav-menu-link>
      <nav-menu-link
        title="Tenant Settings"
        :to="{ path: `/${QUASR_ENV.tenant_id}/${QUASR_ENV.client_id}/tenants/${loginStatus.ten}` }"
        :icon="icons.mdiCogOutline"
      ></nav-menu-link>
      <nav-menu-link
        title="Your Security"
        :to="{ path: `/${QUASR_ENV.tenant_id}/${QUASR_ENV.client_id}/security` }"
        :icon="icons.mdiShieldLockOutline"
      ></nav-menu-link>

      <nav-menu-section-title title="ENTITIES"></nav-menu-section-title>

      <nav-menu-link
        title="Accounts"
        :to="{ path: `/${QUASR_ENV.tenant_id}/${QUASR_ENV.client_id}/accounts` }"
        :icon="icons.mdiBadgeAccountHorizontalOutline"
      ></nav-menu-link>
      <nav-menu-link
        title="Factors"
        :to="{ path: `/${QUASR_ENV.tenant_id}/${QUASR_ENV.client_id}/factors` }"
        :icon="icons.mdiKeyChain"
      ></nav-menu-link>
      <nav-menu-link
        title="Controls"
        :to="{ path: `/${QUASR_ENV.tenant_id}/${QUASR_ENV.client_id}/controls` }"
        :icon="icons.mdiFormatListChecks"
      ></nav-menu-link>
      <nav-menu-link
        title="Events"
        :to="{ path: `/${QUASR_ENV.tenant_id}/${QUASR_ENV.client_id}/events` }"
        :icon="icons.mdiTimelineTextOutline"
      ></nav-menu-link>
      <nav-menu-link
        title="Extensions"
        :to="{ path: `/${QUASR_ENV.tenant_id}/${QUASR_ENV.client_id}/extensions` }"
        :icon="icons.mdiWebhook"
      ></nav-menu-link>

      <!--
      <nav-menu-group
        title="Controls"
        :icon="icons.mdiFormatListChecks"
      >
        <nav-menu-link
          title="Controls"
          :to="{ path: `/${QUASR_ENV.tenant_id}/${QUASR_ENV.client_id}/controls` }"
          :icon="icons.mdiFormatListChecks"
        ></nav-menu-link>
        <nav-menu-link
          title="Consents"
          :to="{ path: `/${QUASR_ENV.tenant_id}/${QUASR_ENV.client_id}/consents` }"
          :icon="icons.mdiCheckboxMarkedCircleOutline"
        ></nav-menu-link>
        <nav-menu-link
          title="Permissions"
          :to="{ path: `/${QUASR_ENV.tenant_id}/${QUASR_ENV.client_id}/permissions` }"
          :icon="icons.mdiHandFrontRightOutline"
        ></nav-menu-link>
      </nav-menu-group>
      -->

      <nav-menu-section-title title="BRANDING"></nav-menu-section-title>

      <nav-menu-link
        title="Hosted Login Page"
        :to="{ path: `/${QUASR_ENV.tenant_id}/${QUASR_ENV.client_id}/hlp/${loginStatus.ten}` }"
        :icon="icons.mdiFileKeyOutline"
      ></nav-menu-link>

      <nav-menu-section-title title="APIs"></nav-menu-section-title>

      <nav-menu-link
        title="APIs"
        :to="{ path: `/${QUASR_ENV.tenant_id}/${QUASR_ENV.client_id}/apis` }"
        :icon="icons.mdiApi"
      ></nav-menu-link>

      <nav-menu-section-title title="SYSTEM INFO"></nav-menu-section-title>

      <div style="font-size:8px;padding:0px 0px 10px 25px">
        <div
          v-show="tenant.label"
          :style="`margin: 0px 0px 10px 0px; padding:5px 5px 5px 5px; color:white; background:${tenantColor}; text-align:center; font-size: 15px`"
        >
          {{ tenant.label }}
        </div>
        <div>Tenant: {{ loginStatus.ten }}</div>
        <div>Account: {{ loginStatus.sub }}</div>
        <div>Quasr Tenant Admin v2024.08.19</div>
      </div>
    </v-list>
    <!--
    <a
      href="https://themeselection.com/products/materio-vuetify-vuejs-admin-template"
      target="_blank"
      rel="nofollow"
    >
      <v-img
        :src="require(`@/assets/images/pro/upgrade-banner-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
        alt="upgrade-banner"
        transition="scale-transition"
        class="upgrade-banner mx-auto"
        max-width="230"
      ></v-img>
    </a>
    -->

    <!-- Navigation Items User -->
    <v-list
      v-if="uiMode === 'account'"
      expand
      shaped
      class="vertical-nav-menu-items pr-5"
    >
      <nav-menu-link
        title="Account & Billing"
        :to="{ path: `/${QUASR_ENV.tenant_id}/${QUASR_ENV.client_id}/billing` }"
        :icon="icons.mdiBadgeAccountHorizontalOutline"
      ></nav-menu-link>

      <nav-menu-link
        v-show="!isOnboarding"
        title="Tenants"
        :to="{ path: `/${QUASR_ENV.tenant_id}/${QUASR_ENV.client_id}/tenants` }"
        :icon="icons.mdiHomeGroup"
      ></nav-menu-link>

      <nav-menu-link
        v-show="!isOnboarding"
        title="Usage & Statistics"
        :to="{ path: `/${QUASR_ENV.tenant_id}/${QUASR_ENV.client_id}/stats` }"
        :icon="icons.mdiGauge"
      ></nav-menu-link>

      <nav-menu-link
        v-show="!isOnboarding"
        title="Security"
        :to="{ path: `/${QUASR_ENV.tenant_id}/${QUASR_ENV.client_id}/security` }"
        :icon="icons.mdiShieldLockOutline"
      ></nav-menu-link>

      <nav-menu-link
        v-show="false"
        title="Data & Privacy"
        :to="{ path: `/${QUASR_ENV.tenant_id}/${QUASR_ENV.client_id}/data-privacy` }"
        :icon="icons.mdiShieldAccountOutline"
      ></nav-menu-link>

      <nav-menu-section-title title="SYSTEM INFO"></nav-menu-section-title>

      <div style="font-size:8px;padding:10px 0px 10px 25px">
        <div>Tenant: {{ loginStatus.ten }}</div>
        <div>Account: {{ loginStatus.sub }}</div>
        <div v-if="customer">
          Customer: {{ customer.id }}
        </div>
        <div>Quasr Account v2024.08.19</div>
      </div>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiHomeOutline,
  mdiHomeGroup,
  mdiHome,
  mdiAccountOutline,
  mdiFingerprint,
  mdiCogOutline,
  mdiAlphaTBoxOutline,
  mdiEyeOutline,
  mdiCreditCardOutline,
  mdiTable,
  mdiCurrencyEur,
  mdiFileOutline,
  mdiBadgeAccountOutline,
  mdiFormSelect,
  mdiAccountCogOutline,
  mdiAccountMultipleOutline,
  mdiTicketConfirmationOutline,
  mdiAccountBoxMultipleOutline,
  mdiBadgeAccountHorizontalOutline,
  mdiRobotOutline,
  mdiRobotConfusedOutline,
  mdiWavesArrowRight,
  mdiKeyChain,
  mdiWebhook,
  mdiCodeTags,
  mdiShieldAccountOutline,
  mdiShieldLockOutline,
  mdiDotsHexagon,
  mdiApi,
  mdiGauge,
  mdiFormatListChecks,
  mdiPuzzleOutline,
  mdiFlashOutline,
  mdiHandFrontRightOutline,
  mdiCheckboxMarkedCircleOutline,
  mdiFileKeyOutline,
  mdiTimelineTextOutline,
} from '@mdi/js'
import * as QuasrHelper from '@quasr-io/helper'
import gql from 'graphql-tag'
import { listTenants, getTenant } from '../../../graphql/queries'
import NavMenuSectionTitle from './components/NavMenuSectionTitle.vue'
import NavMenuLink from './components/NavMenuLink.vue'
import store from '../../../store'

export default {

  /**
   * components
   */
  components: {
    NavMenuSectionTitle,
    NavMenuLink,
  },

  /**
   * props
   */
  props: {
    isDrawerOpen: { type: Boolean, default: null },
  },

  /**
   * data
   */
  data() {
    return {
      customer: { properties: {} },
      tenants: [],
      tenant: {},
      tenantColor: localStorage.getItem(`tenantColor.${this.QUASR_ENV.tenant_id}`) || '#eee', // #b4b71d
    }
  },

  computed: {
    /**
     * isOnboarding
     */
    isOnboarding: {
      get() {
        return !(this.customer && this.customer.email && this.customer.metadata.email_verified === 'true')
      },
      set() {
      },
    },
  },

  /**
   * created
   */
  async created() {
    this.checkOnboardingFlag()

    // listen for any tenant color change, to update the color badge respectively
    this.$eventHub.$on('tenant-label-change-event', this.tenantLabelChangeHandler)
    this.$eventHub.$on('tenant-color-change-event', this.tenantColorChangeHandler)

    // listen for customer record change, to update the side bar system info
    this.$eventHub.$on('customer-change-event', this.customerChangeHandler)

    // load tenant in order to update the color properly at first UI load
    if (this.uiMode === 'admin' && this.QUASR_ENV.tenant_id) await this.getTenant()
  },

  /**
   * setup
   */
  setup() {
    const ENV = QuasrHelper.getEnv()
    const API_ENV = QuasrHelper.getApiEnv()
    const QUASR_ENV = QuasrHelper.getTenantAndClient()
    const loginStatus = QuasrHelper.checkLoginStatus(QUASR_ENV.tenant_id)
    const uiMode = document.location.hostname.startsWith('account') ? 'account' : 'admin'

    return {
      ENV,
      API_ENV,
      QUASR_ENV,
      loginStatus,
      uiMode,
      icons: {
        mdiHomeOutline,
        mdiHomeGroup,
        mdiHome,
        mdiAlphaTBoxOutline,
        mdiEyeOutline,
        mdiCreditCardOutline,
        mdiTable,
        mdiFileOutline,
        mdiFormSelect,
        mdiBadgeAccountOutline,
        mdiAccountCogOutline,
        mdiAccountMultipleOutline,
        mdiTicketConfirmationOutline,
        mdiAccountBoxMultipleOutline,
        mdiCogOutline,
        mdiRobotOutline,
        mdiRobotConfusedOutline,
        mdiFingerprint,
        mdiWavesArrowRight,
        mdiKeyChain,
        mdiWebhook,
        mdiCodeTags,
        mdiCurrencyEur,
        mdiAccountOutline,
        mdiBadgeAccountHorizontalOutline,
        mdiShieldAccountOutline,
        mdiShieldLockOutline,
        mdiDotsHexagon,
        mdiApi,
        mdiGauge,
        mdiFormatListChecks,
        mdiPuzzleOutline,
        mdiFlashOutline,
        mdiHandFrontRightOutline,
        mdiCheckboxMarkedCircleOutline,
        mdiFileKeyOutline,
        mdiTimelineTextOutline,
      },
    }
  },

  /**
   * beforeDestroy
   */
  beforeDestroy() {
    if (this.$eventHub) {
      this.$eventHub.$off('tenant-color-change-event')
      this.$eventHub.$off('tenant-label-change-event')
      this.$eventHub.$off('customer-change-event')
    }
  },

  /**
   * methods
   */
  methods: {
    /**
     * getLinkStyle
     */
    getLinkStyle() {
      return 'font-size: 12px; color:'.concat(this.$vuetify.theme.dark ? 'white' : '#3C78D8')
    },

    /**
     * checkOnboardingFlag
     */
    checkOnboardingFlag() {
      this.isOnboarding = document.location.search.includes('onboarding') || document.location.hash === '#onboarding'
    },

    /**
     * getTenant
     */
    async getTenant() {
      const tenant = await store.getAppSyncClient().query({ query: gql(getTenant), variables: { id: this.QUASR_ENV.tenant_id } })
      this.tenant = tenant.data.getTenant
      this.tenantColor = this.tenant?.config?.color || localStorage.getItem(`tenantColor.${this.QUASR_ENV.tenant_id}`) || '#eee'
    },

    /**
     * getTenants
     */
    async getTenants() {
      const tenants = await store.getAppSyncClient().query({ query: gql(listTenants), variables: { filter: { account: { eq: this.loginStatus.sub } }, limit: 1000 } })
      tenants.data.listTenants.items = QuasrHelper.sortListByKey(tenants.data.listTenants.items, 'label')
      this.tenants = tenants.data.listTenants.items
    },

    /**
     * tenantColorChangeHandler
     */
    tenantColorChangeHandler(val) {
      this.tenantColor = val
      localStorage.setItem(`tenantColor.${this.QUASR_ENV.tenant_id}`, val)
    },

    /**
     * tenantLabelChangeHandler
     */
    tenantLabelChangeHandler(val) {
      this.tenant.label = val
    },

    /**
     * customerChangeHandler
     */
    customerChangeHandler(val) {
      this.customer = val
    },
  },
}
</script>

<style lang="scss" scoped>
.app-title {
  font-size: 1.25rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
}

// ? Adjust this `translateX` value to keep logo in center when vertical nav menu is collapsed (Value depends on your logo)
.app-logo {
  transition: all 0.18s ease-in-out;
  .v-navigation-drawer--mini-variant & {
    transform: translateX(-4px);
  }
}

@include theme(app-navigation-menu) using ($material) {
  background-color: map-deep-get($material, 'background');
}

.app-navigation-menu {
  .v-list-item {
    &.vertical-nav-menu-link {
      ::v-deep .v-list-item__icon {
        .v-icon {
          transition: none !important;
        }
      }
    }
  }
}

// You can remove below style
// Upgrade Banner
/*
.app-navigation-menu {
  .upgrade-banner {
    position: absolute;
    bottom: 13px;
    left: 50%;
    transform: translateX(-50%);
  }
}
*/
</style>
