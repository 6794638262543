<template>
  <component
    :is="resolveLayout"
  >
    <router-view
      :key="$route.fullPath"
    />
  </component>
</template>

<script>

// import axios from 'axios'
import { AWSAppSyncClient } from 'aws-appsync'
import { v4 as uuid } from 'uuid'
import * as QuasrHelper from '@quasr-io/helper'
import axios from 'axios'
import { computed } from '@vue/composition-api'
import { useRouter } from '@/utils'
import LayoutBlank from '@/layouts/Blank.vue'
import LayoutContent from '@/layouts/Content.vue'
import store from './store'

export default {
  name: 'App',

  /**
   * Components
   */
  components: {
    LayoutBlank,
    LayoutContent,
  },

  /**
   * setup
   */
  setup() {
    const uiMode = document.location.hostname.startsWith('account') ? 'account' : 'admin'
    const { route } = useRouter()
    const resolveLayout = computed(() => {
      // Handles initial route
      if (route.value.name === null) return null
      if (route.value.meta.layout === 'blank') return 'layout-blank'

      return 'layout-content'
    })
    const appSyncClient = undefined

    return {
      uiMode,
      resolveLayout,
      appSyncClient,
    }
  },

  /**
   * created
   */
  created() {
    let loginStatus = {}
    const QUASR_ENV = QuasrHelper.getTenantAndClient(this.uiMode === 'admin')
    if (!QUASR_ENV.tenant_id) {
      document.location.href = 'https://quasr.io'
    } else if (document.location.pathname !== `/${QUASR_ENV.tenant_id}/${QUASR_ENV.client_id}/`
      && document.location.pathname !== `/${QUASR_ENV.tenant_id}/${QUASR_ENV.client_id}`
      && !document.location.pathname.endsWith('/login')
      && !document.location.pathname.endsWith('/blank')
      && !document.location.pathname.endsWith('/error')
      && !document.location.pathname.endsWith('/enrollment-callback')) {
      if (!store.getAppSyncClient) store.getAppSyncClient = this.initAppSyncClient
      loginStatus = QuasrHelper.checkLoginStatus(QUASR_ENV.tenant_id)
      if (!loginStatus.logged_in) {
        this.$router.push(`/${QUASR_ENV.tenant_id}/${QUASR_ENV.client_id}/login`)
      } else {
        this.initAppSyncClient()
      }
    } else if (!store.getAppSyncClient) store.getAppSyncClient = this.initAppSyncClient

    const paramsString = document.location.search
    const searchParams = new URLSearchParams(paramsString)

    if (searchParams.get('error')) {
      this.isLoading = false
      this.message = searchParams.get('error')
      window.opener.loginCallback({ success: false, message: this.message })
      store.message = this.message
      this.$router.push(`/${QUASR_ENV.tenant_id}/${QUASR_ENV.client_id}/security#error=${this.message}`)
      window.close()
    } else if (searchParams.get('id') && searchParams.get('input')) {
      this.enrollmentFinalization(searchParams.get('id'), searchParams.get('input'))
    }
  },

  /**
   * methods
   */
  methods: {
    /**
     * initAppSyncClient
     */
    initAppSyncClient() {
      localStorage.removeItem('reduxPersist::appsync-metadata')
      localStorage.removeItem('reduxPersist::appsync')
      localStorage.removeItem('reduxPersist::offline')
      // eslint-disable-next-line
      if (true || !store.appSyncClient) { // https://github.com/awslabs/aws-mobile-appsync-sdk-js/issues/359
        const uiMode = document.location.hostname.startsWith('account') ? 'account' : 'admin'
        const QUASR_ENV = QuasrHelper.getTenantAndClient(uiMode === 'admin')
        const loginStatus = QuasrHelper.checkLoginStatus(QUASR_ENV.tenant_id)
        store.appSyncClient = new AWSAppSyncClient({
          disableOffline: true,
          cacheOptions: {
            addTypename: false,
          },
          offlineConfig: {
            keyPrefix: uuid(),
          },
          url: `https://${QUASR_ENV.tenant_id}.${QuasrHelper.getGraphQLUri().slice('https://'.length)}`,
          region: 'eu-central-1',
          auth: {
            type: 'OPENID_CONNECT',
            jwtToken: async () => 'Bearer '.concat(loginStatus.access_token),
          },
        }) // https://stackoverflow.com/questions/59743243/aws-appsync-javascript-query-example-and-input-syntax
      }

      return store.appSyncClient
    },

    /**
     * enrollmentFinalization
     */
    async enrollmentFinalization(id, input) {
      const QUASR_ENV = QuasrHelper.getTenantAndClient(this.uiMode === 'admin')
      const loginStatus = QuasrHelper.checkLoginStatus(QUASR_ENV.tenant_id)
      const API_ENV = QuasrHelper.getApiEnv()
      const response = await axios({
        method: 'POST',
        url: API_ENV === 'prod' ? `https://${QUASR_ENV.tenant_id}.api.quasr.io/factors` : `https://${QUASR_ENV.tenant_id}.api-${API_ENV}.quasr.io/factors`,
        data: [{
          id,
          input,
        }],
        headers: {
          Authorization: `Bearer ${loginStatus.access_token}`,
        },
      })

      // const { result } = response.data
      // const { enrollment } = response.data
      const { error } = response.data
      this.isLoading = false
      this.message = error || 'Authentication successful. You can now close this browser tab and return to the Quasr Account Administration UI.'
      window.opener.loginCallback({ success: !error, message: this.message })
      window.close()
    },
  },
}
</script>
